import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-pat-assignment',
  templateUrl: './pat-assignment.component.html',
  styleUrls: ['./pat-assignment.component.css']
})
export class PatAssignmentComponent implements OnInit {

  @Input() patientId:any;
  constructor(public activeModal:NgbActiveModal,private clinicServices:ClinicService) { }

  ngOnInit(): void {
    this.getPatientDetails()
  }

  patientInfo:any;
  getPatientDetails(){

    this.clinicServices.findUserById(this.patientId).subscribe(res => {
    this.patientInfo = JSON.parse(res['data'])})
  }

  book(){
    this.activeModal.close('OPD')
  }

  assignIpd(){
    this.activeModal.close('IPD')
  }

  assignEmergency(){
    this.activeModal.close('EMERGENCY')
  }

}
