import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { ApicallsService } from 'src/app/apicalls.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticServiceController {
  diagnosticUrl = environment.diagnosticBaseUrl
  utilitiesUrl = environment.utilitiesUrl
  elesticUrl = environment.elesticSearchUrl;
  pdfUrl = environment.pdfUrl;

  baseScheduleReq;
  myMethod$: Observable<any>;
  private myMethodSubject = new Subject<any>();
  dataStringSource = new Subject<any>();
  httpHeader = new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) })
  httpHeader2 = { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) }
  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiServices: ApicallsService) {
    this.myMethod$ = this.myMethodSubject.asObservable();

    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.diagnosticUrl = environment.diagnosticBaseUrl1
      this.utilitiesUrl = environment.utilitiesUrl1
      this.elesticUrl = environment.elesticSearchUrl1;
      this.pdfUrl = environment.pdfUrl1;
    }
  }

  diagnosticGetPath(path) {
    return this.apiServices.get(this.diagnosticUrl + path, {})
  }
  diagnosticGetBody(endpoint, body) {
    return this.apiServices.get(this.diagnosticUrl + endpoint, body)
  }
  diagnosticPostPath(endpoint, path) {
    return this.apiServices.get(this.diagnosticUrl + endpoint + path, {})
  }
  private package = new Subject<any>();

  package$ = this.package.asObservable();
  packageData
  sendPackage(pack) {
    this.packageData = pack;
    this.myMethodSubject.next(pack);
    this.dataStringSource.next(this.packageData);
  }
  getPack() {
    return this.packageData
  }

  dia; packagebaseScheduleReq
  sendDataToBaseschedule(value) {
    this.baseScheduleReq = value
    this.dataStringSource.next(this.baseScheduleReq);
  }
  sendDataToPackageschedule(value) {
    this.packagebaseScheduleReq = value
    this.dataStringSource.next(this.packagebaseScheduleReq);
  }
  gnosticPostPathSession(endpoint, path) {
    let body = {}
    return this.apiServices.post(this.diagnosticUrl + endpoint + path, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  diagnosticGetSessionPath(endpoint, path) {
    return this.apiServices.get(this.diagnosticUrl + endpoint + path, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getInvoiceForPreviousAppointment(body){
    return this.apiServices.post(this.diagnosticUrl + 'invoice/criteria',body,{})
  }
  getSubmenuItems(id) {
    return this.apiServices.get(this.diagnosticUrl + 'menu/getAllMenuAndSubItems/' + id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPackages(id) {
    return this.apiServices.get(this.diagnosticUrl + 'menu/packages/get/' + id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  diagnosticPostBody(endpoint, body) {
    return this.apiServices.post(this.diagnosticUrl + endpoint, body, {})
  }
  diagnosticPostSessionBody(endpoint, body) {
    return this.apiServices.post(this.diagnosticUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  uploadReport(endpoint, body) {
    return this.apiServices.post(this.diagnosticUrl + endpoint, body, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  updateNotifications(endpoint, request) {
    return this.apiServices.post(this.diagnosticUrl + 'notificationalerts/updateNotificationalerts/' + endpoint, request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  diagnosticPutSessionBody(endpoint, body) {
    return this.apiServices.put(this.diagnosticUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  diagnosticGetPathSession(path) {
    return this.apiServices.get(this.diagnosticUrl + path, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  diagnosticpPathSession(path) {
    return this.apiServices.post(this.diagnosticUrl + path, {}, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  getDCpreviousInvoiceData(invoiceID) {
    return this.apiServices.get(this.diagnosticUrl + 'invoice/getinvoiceByInvoiceId?invoiceId=' + invoiceID, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId': this.globalStorage.getItem('forUserId'), "accept": "*/*" }), observe: 'response' })
  }

  downloadDCPdf(body): Observable<any>{
    return this.http.post(this.pdfUrl + 'diagnostic', body,{responseType: 'blob' as 'json'});
  }

  sendFeedback(formData) {
    return this.apiServices.post(this.utilitiesUrl + 'feedback/create', formData, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  diagnosticGetSessionBody(endpoint, body) {
    return this.apiServices.get(this.diagnosticUrl + endpoint, body)
  }
  diagnosticPostSessionBodyParams(endpoint, body, id) {
    let params = new HttpParams().set('appointmentId', id);
    return this.apiServices.post(this.diagnosticUrl + endpoint, body, { params: params, headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  diagnosticPutSession(endpoint, body) {
    return this.apiServices.put(this.diagnosticUrl + endpoint, body, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getOtp(endpoint, passwordType, verificationId) {

    let params = new HttpParams()
    params = params.set('passwordType', passwordType),
      params = params.set('verificationId', verificationId)
    return this.apiServices.post(this.diagnosticUrl + endpoint, {},
      { params: params, headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  deleteAppointment(endpoint, path) {
    return this.apiServices.delete(this.diagnosticUrl + endpoint + path, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  deleteMenu(id) {
    return this.apiServices.delete(this.diagnosticUrl + 'menu/deleteMenu/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  deleteSchedule(id) {
    return this.apiServices.delete(this.diagnosticUrl + 'schedulehours/id/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  deletePackage(id) {
    return this.apiServices.delete(this.diagnosticUrl + 'menu/deletePackage/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  deleteSubMenu(id) {
    return this.apiServices.delete(this.diagnosticUrl + 'menu/deleteSubMenu/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getForgetPasswordOtp(emailid, resetPasswordLink, body) {
    return this.apiServices.post(this.diagnosticUrl + 'password/forgot?emailId=' + emailid + '&resetPasswordLink=' + resetPasswordLink, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  createNewPassword(body) {
    return this.apiServices.post(this.diagnosticUrl + 'password/reset', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resetPassword(body) {
    return this.apiServices.post(this.diagnosticUrl + 'resetPasswordForDiagnosticcenteruser', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  otpVerify(email, otp) {
    return this.apiServices.get(this.diagnosticUrl + 'otpverification?emailId=' + email + '&otp=' + otp, {})
  }
  emailVerify(email, otp) {
    return this.apiServices.get(this.diagnosticUrl + 'emailVerificationForDiagnosticcenterUser?emailid=' + email + '&emailotp=' + otp, {})
  }
  getAppointments(path, req) {
    return this.apiServices.post(this.diagnosticUrl + path, req, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  updateProfile(endpoint, dcId, data) {
    return this.apiServices.post(this.diagnosticUrl + endpoint + dcId, data, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getPersonalDetails(id) {
    return this.apiServices.post(this.diagnosticUrl + 'get/personaldetail?diagnosticcenterId=' + id, {}, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  fetchDiagnosticCenter(endpoint, id) {
    return this.apiServices.get(this.diagnosticUrl + endpoint + id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  searchPackageByElestic(data) {
    return this.apiServices.get(this.elesticUrl + 'elasticsearchDiagnosticcenterController/searchPackage/' + data+'/'+this.globalStorage.getItem('diagnosticCenterAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  searchMenuByElestic(data) {
    return this.apiServices.get(this.elesticUrl + 'elasticsearchDiagnosticcenterController/searchMenu/' + data, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  searchMenuByElesticByDcAddressId(data, id) {
    return this.apiServices.get(this.elesticUrl + 'elasticsearchDiagnosticcenterController/searchMenu/' + data + '/' + id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  saveTestSignificance(body) {
    return this.apiServices.post(this.diagnosticUrl + 'saveTestSignificance', body, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  saveTestSpecification(body){
    return this.apiServices.post(this.diagnosticUrl + 'saveTestSpecification', body, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  saveTestReference(body){
    return this.apiServices.post(this.diagnosticUrl + 'saveTestReference', body, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  saveTestParameter(body){
    return this.apiServices.post(this.diagnosticUrl + 'saveTestParameter', body, { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  fetchTestParameterInformation(submenuId){
    return this.apiServices.get(this.diagnosticUrl + 'fetchTestParameterInformation?subMenuId=' + submenuId , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  activeTestSpecification(id){
    return this.apiServices.post(this.diagnosticUrl + 'activeTestSpecification?testSpecificationId=' + id , {},{ headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  deactiveTestSpecification(id){
    return this.apiServices.delete(this.diagnosticUrl + 'deleteTestSpecification?testSpecificationId=' + id , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  activeTestReference(id){
    return this.apiServices.post(this.diagnosticUrl + 'activeTestReference?testReferenceId=' + id , {},{ headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  deactiveTestReference(id){
    return this.apiServices.delete(this.diagnosticUrl + 'deleteTestReference?testReferenceId=' + id , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  activeTestParameter(id){
    return this.apiServices.post(this.diagnosticUrl + 'activeTestParameter?testParameterId=' + id , {},{ headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  deactiveTestParameter(id){
    return this.apiServices.delete(this.diagnosticUrl + 'deleteTestParameter?testParameterId=' + id , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  saveTestParameterQuantity(body){
    return this.apiServices.post(this.diagnosticUrl + 'saveTestParameterQuantity',body,{ headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  fetchTestParameterQuantity(id){
    return this.apiServices.get(this.diagnosticUrl + 'fetchTestParameterQuantity?testParameterId=' + id , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  saveTestParameterResult(body){
    return this.apiServices.post(this.diagnosticUrl + 'saveTestParameterResultList',body,{ headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  saveTestParameterMethod(body){
    return this.apiServices.post(this.diagnosticUrl + 'saveTestParameterMethod',body,{ headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  testParameterMethodList(addressId){
    return this.apiServices.get(this.diagnosticUrl + 'testParameterMethodList/' + addressId , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  } 

  getTestParameterResultList(appointmentId){
    return this.apiServices.get(this.diagnosticUrl + 'testParameterResultList/' + appointmentId , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }
  
  getTestParameterResultsListByDcAddressId(dcAddressId,appointmentId){
    return this.apiServices.get(this.diagnosticUrl + 'testParameterResultList?diagnosticcenterAddressId='+dcAddressId+'&diagnosticcenterAppointmentId=' + appointmentId , { headers: new HttpHeaders({ "user-session": JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DIAGNOSTICCENTER', 'forUserId':this.globalStorage.getItem('forUserId') }) });
  }

  checkOldPassword(email,password){
    return this.apiServices.get(this.diagnosticUrl+'oldPasswordVerification?emailId='+email+'&password='+password,{})
  }

  private sortOrder: string = 'asc';
  private sortField: string = '';

  sort(array: any[], field: string): any[] {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }

    this.sortField = field;
    const comparison = this.sortOrder === 'asc' ? 1 : -1;

    return array.sort((a, b) => {
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return a[field].localeCompare(b[field]) * comparison;
      } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
        return (a[field] - b[field]) * comparison;
      } else {
        return 0;
      }
    });
  }



}
