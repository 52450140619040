import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ClinicService } from '../../clinic.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { ApicallsService } from 'src/app/apicalls.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, tap, map, startWith } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch, NoMatch } from 'src/app/_helpers/must_match.validators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { timeStamp } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewpatientComponent } from 'src/app/shared/models/newpatient/newpatient.component';
import { CookieService } from 'ngx-cookie-service';
import { BookDoctorAppointmentComponent } from 'projects/ivf/src/app/book-doctor-appointment/book-doctor-appointment.component';
import { UserVitalsHistoryComponent } from 'src/app/common-components/user-vitals-history/user-vitals-history.component';
import { ClinicOpdAppointmentBookingComponent } from 'src/app/shared/components/clinic-opd-appointment-booking/clinic-opd-appointment-booking.component';
import { DiscountRequestsComponent } from 'src/app/shared/components/discount-requests/discount-requests.component';
import { PatientAssignmentComponent } from '../../patient-assignment/patient-assignment.component';
import { PatAssignmentComponent } from 'src/app/shared/models/pat-assignment/pat-assignment.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SendMessageComponent } from 'src/app/commonComponents/internal-message/send-message/send-message.component';
import { InternalMessageComponent } from 'src/app/commonComponents/internal-message/internal-message.component';
import { gpsService } from 'src/app/shared/services/gps.service';
declare var $: any;

@Component({
  selector: 'app-clinic-navbar',
  templateUrl: './clinic-navbar.component.html',
  styleUrls: ['./clinic-navbar.component.css']
})
export class ClinicNavbarComponent implements OnInit {
  loginUser;radioMessage='Search & Booking'
  options: string[] = ['OPD', 'IPD', 'EMERGENCY'];
  filteredOptionsD: Observable<string[]>; patientSelected;
  filteredUsers:Observable<string[]>
  optionsD = [];imgURL;searchPhoneNum;
  userOption=[];
  registerForm: FormGroup;
  userFoundForm:FormGroup;
  addInPatientForm: FormGroup;
  createNewUser=false;
  searchphNum=true
  primaryAddress: FormGroup; selectedValue4; selectedValue3; primarysubmitted: boolean = false; successCredentials: boolean = false
  myControl = new FormControl('');
  myControlUser = new FormControl('');
  minDate = new Date(1925, 0, 1);
  maxDate = new Date(); search3; search4;
  genderList: any[] = [{ id: 1, value: 'Male' }, { id: 2, value: 'Female' }, { id: 3, value: 'Others' }]
  bloodGroupList: any[] = [{ id: 1, value: 'A+' }, { id: 2, value: 'A-' }, { id: 3, value: 'B+' }, { id: 4, value: 'B-' }, { id: 5, value: 'AB+' }, { id: 6, value: 'AB-' }, { id: 7, value: 'O+' }, { id: 8, value: 'O-' }]
  toastr: any;


  constructor(private globalStorage: GlobalStorageService, private router: Router, private scripts: ScriptsService, private clinicServices: ClinicService, private toastrService: ToastrService,
    private apiservice: ApicallsService, private formBuilder: FormBuilder, private service: AuthService,
    private cookieService : CookieService,private sanitizer: DomSanitizer,private gps: gpsService, 
    private patientService: PatientService, private pharmaservice: pharmaService,private modal:NgbModal) {
   // this.checkProfileCompletedOrNot();
  }


  roles; AppointmentsPermission = false; AssociatesPermission = true;
  ProfilePermission = false;
  RoomsPermission = true;
  BranchPermission = true;
  SurgeriesPermission = true;
  UsersPermission = false;
  adminPermissions=false;
associatePermissions=false;
  ngOnInit(): void {
    // var myvar= setInterval(currentTime, 1000);
    //roles
    var role = JSON.parse(this.globalStorage.getItem('roles'))
    this.roles = role[0]?.['menu']
    var setAppointmentsPermission = this.roles?.filter(obj => obj?.menuName == "Appointments")
    var setAssociatesPermission = this.roles?.filter(obj => obj?.menuName == "Associates")
    var setRoomsPermission = this.roles?.filter(obj => obj?.menuName == "Rooms")
    var setBranchPermission = this.roles?.filter(obj => obj?.menuName == "Branch")
    var setSurgeriesPermission = this.roles?.filter(obj => obj?.menuName == "Surgeries")
    var setUsersPermission = this.roles?.filter(obj => obj?.menuName == "Users")
    var setProfilePermission = this.roles?.filter(obj => obj?.menuName == "Profile")
    var adminTab=this.roles?.filter(obj=>obj?.menuName=='Admin')
    console.log(adminTab)
    // var adminTab=this.roles.filter(obj=>obj?.menuName=='Asscoiates')

   this.getHospitalAddress()
    this.getMessagesCount()

    var menu=role[0]?.['menu']
    console.log(menu)
    var subMenu = []
    for(let i=0;i<menu?.length;i++){
      subMenu.push(menu[i]?.['submenus'])
    }
    console.group(subMenu)

    if (setAppointmentsPermission?.[0]?.active == 'Y') {
      this.AppointmentsPermission = true
    }



    if (setAppointmentsPermission?.[0]?.active == 'Y') {
      this.AppointmentsPermission = true
    }
    if (setAssociatesPermission?.[0]?.active == 'Y') {
      this.AssociatesPermission = true
    }
    if (setProfilePermission?.[0]?.active == 'Y') {
      this.ProfilePermission = true
    }
    if (setRoomsPermission?.[0]?.active == 'Y') {
      this.RoomsPermission = true
    }
    if (setBranchPermission?.[0]?.active == 'Y') {
      this.BranchPermission = true
    }
    if (setSurgeriesPermission?.[0]?.active == 'Y') {
      this.SurgeriesPermission = true
    }
    if (setUsersPermission?.[0]?.active == 'Y') {
      this.UsersPermission = true
    }
    if(adminTab?.[0]?.active=='Y'){
      this.adminPermissions=true
    }

    //roles end
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "full_number", nationalMode: "false",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
   // this.checkProfileCompletedOrNot()
    this.selectedOption = 'OPD'
    $('#mat-radio-5').toggleClass('mat-radio-checked')
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: [''],
      emergencyContactName: ['', Validators.required],
     emergenyContactNumber:['',[Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      // userName: ['',],
      email: ['', [Validators.required, Validators.email]],
      contactNum: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      // password: ['', [Validators.required,
      // Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      // confirmPassword: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      bloodgroup: ['', [Validators.required]]

    },
      {
        validators: [ NoMatch('firstName', 'lastName'),NoMatch('contactNum','emergenyContactNumber')]
      }
    );
    this.userFoundForm=this.formBuilder.group({
      firstName: [''],
      lastName:[''],
      email:[''],
      contactNum:[''],
      dob:[''],
      gender:[''],
      userName:[''],
      userId:['']
    }
    )
    this.primaryAddress = this.formBuilder.group({
      addressId: [],
      isPrimary: ['Y'],
      city: ['', Validators.required],
      country: ['', Validators.required],
      longitude: [''],
      state: ['', Validators.required],
      latitude: [''],
      street1: ['', Validators.required],
      street2: [''],
      zip: ['', Validators.required],
      cc: ['', Validators.required],
      postalLocation: ['', Validators.required]
    });
    this.addInPatientForm = this.formBuilder.group({
      complaint: [''],
      primaryDoctor: [''],
      admissionDate: [''],

    })
   // this.scripts.loadScripts()
    this.filteredOptionsD = this.myControl.valueChanges.pipe(
      debounceTime(300), // Wait for 300 ms pause in events
      distinctUntilChanged(), // Ignore if next search term is same as previous
      startWith(''),
      map(value => this._filterD(value || '')),
    );
    this.filteredUsers=this.myControlUser.valueChanges.pipe(
      debounceTime(300), // Wait for 300 ms pause in events
      distinctUntilChanged(), // Ignore if next search term is same as previous
      startWith(''),
      map(value => this._filterU(value || '')),
    );
    $("button, a, body").on("click", function (e) {
      $('.tooltip').hide();
      $('[data-toggle="tooltip"]').tooltip("hide");
    });
    //  this.getAssociatedUsers()
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });
    var data = JSON.parse(this.globalStorage.getItem('clinicDetails'));
    this.loginUser = data;
    this.loadLogo()
    this.getNotifications()
    this.getDiscountRquests()
  }



  private _filterD(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsD?.filter(option => option['name'].toLowerCase().includes(filterValue));
  }
  private _filterU(value: string): string[] {
    console.log('value',value)
    const filterValues = value.toLowerCase();
    return this.userOption?.filter(option => option['number'].toLowerCase().includes(filterValues));
  }
  get f() { return this.registerForm.controls; }
  get h() { return this.primaryAddress.controls; }
  navigateTo(url) {
    // var request = {
    //   'password': this.globalStorage.getItem('password'),
    //   'userName': this.globalStorage.getItem('userName'),
    //   "type": "string"
    // }
    // this.pharmaservice.globalPostPharma('user/login', (request)).subscribe(res => {
    //   if (res.body['status'] == "SUCCESS") {
    //     //this.ToastrService.success('Logged in successfully')
    //     this.globalStorage.setItem('userData', JSON.stringify(res.body['data']));
    //     sessionStorage.setItem('pharmacyData', JSON.stringify(res.body['data']))
    //     this.globalStorage.setItem('loggedInAs', 'PHARMACY')
    //     var data = this.globalStorage.getItem('userData')
    //     var objs = JSON.parse(data)
    //     var today = moment(objs['lastLogin']).format('YYYY-MM-DD HH:mm:ss');
    //     this.globalStorage.setItem('pharmacyId', objs['pharmacyaddressId'])
    //     this.globalStorage.setItem('pharmacyAddressId', objs['pharmacyaddressId'])
    //     this.globalStorage.setItem('phpswd', this.registerForm.value.password)
    //     this.globalStorage.setItem('userId', objs['pharmacyUserId']);
    //     this.globalStorage.setItem('forUserId', objs['pharmacyUserId'])
    //     this.globalStorage.setItem('userSession', JSON.stringify(res.body['userSession']))

    //     //roles
    //     this.globalStorage.setItem('roles', JSON.stringify(res.body['data']['roles']))
    //     //roles
    //     this.apiservice.isUserSessionExits = true
    //     this.apiservice.isforUserIdExits = true
    //     this.apiservice.ismoduleNameExits = true
    //     // this.scripts.loadCssStyles()
    //     this.router.navigate(['/clinic-pharmacy'])
    //     this.globalStorage.setItem('theme', 'cureMetric.light.blue.min.css')
    //   }
    //   else {
    //     this.toastrService.error('Invalid credentials either Username or Password wrong')
    //   }
    // }
    //   , error => {

    //   }
    // )
    // this.clinicServices.clinicProfileCompletedOrNot(this.globalStorage.getItem('forUserId')).subscribe(res => {
    //   if (res['data']['isPersonalDetailCompleted'] == 'N') {
    //     //this.router.navigate(['/clinic-personalDetails']);
    //   }
    //   else {
    //     this.router.navigate(['/' + url])
    //   }
    // })
  }
  getFormValue(value){
  }
  checkProfileCompletedOrNot() {
    // this.clinicServices.clinicProfileCompletedOrNot(this.globalStorage.getItem('forUserId')).subscribe(res => {
    //   if (res['data']['isPersonalDetailCompleted'] == 'N') {
    //     this.router.navigate(['/clinic-personalDetails']);
    //   }
    // })
  }
  logOut() {
    this.clinicServices.logOut().subscribe(res => {
      this.router.navigate(['/clinicLogin'])
      this.globalStorage.clear()
      sessionStorage.clear()
      this.cookieService.deleteAll()
      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false

    })
  }
  messagesCount;
  getMessagesCount(){
    this.clinicServices.getMessagesCount(this.globalStorage.getItem('hospitalAddressId'),this.globalStorage.getItem('hospitalUserId')).subscribe(res=>{
      console.log('message res',res)
      this.messagesCount=res['data']['messageCount']
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  submitted = false; addressDetailsOn = false
  closeModal() {
    this.registerForm.reset()
    this.primaryAddress.reset()
    this.registerForm.patchValue({
      gender:'',
      bloodgroup:''
    })
    this.submitted = false
    this.addressDetailsOn = false
    this.invalidCredentials = false;
    this.message = false;
    this.isPhoneNumberExists= false;
    this.userOption=[]
    this.userFound=false
    this.createNewUser=false
    this.searchPhoneNum=''
    this.messageOpen=false
    this.searchphNum=true
  }
  imgPresent=false
  loadLogo(){
    if(this.loginUser?.logo!=null){
      this.imgPresent=true
    }
    const src = "data:image/jpeg;base64," + this.loginUser?.logo
    this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }
  pageNo = 1; reminders; notificationLength; nonViewData; ViewedNotifications; notifyToView; p2 = 1;allNotifications;nonviewLength
  loadRemainders=false
  getNotifications() {
    this.loadRemainders=true
    this.clinicServices.getAllNotifications('notificationalerts/get/all?hospitalId=' + this.globalStorage.getItem('hospitalAddressId') + '&maxResult=' + 100 + '&pageNumber=' + this.pageNo).subscribe(res => {
      this.reminders = res['data'];
      this.loadRemainders=false
      this.notificationLength = this.reminders?.filter((obj) => obj.viewed == 'N').length;
      this.nonViewData = this.reminders.filter((obj) => obj.viewed == 'N');
      this.nonviewLength=this.reminders.filter((obj) => obj.viewed == 'N').length
      this.ViewedNotifications = this.reminders?.filter((obj) => obj.viewed == 'Y').length;
    },error=>{
      this.loadRemainders=false
    })
  }
  getDiscountRquests(){
    this.clinicServices.getRequests(parseInt(this.globalStorage.getItem('hospitalAddressId')),parseInt(this.globalStorage.getItem('hospitalUserId'))).subscribe(res=>{
      console.log('req re',res)
      this.requestList=res['data']
    },error=>{

    })
  }
  removeDuplicateItems(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }
  selectedPatient:any
  getSelectedValue(data,e) {
    // this.selectedPatient = data;
    console.log(data,"TypeAhead Search Name")
   if (e == 'y') {
    this.selectedPatient = data;
    // $('#caseDetailsForm').modal('show')
    // var name = data.split(" ")[0];
    // var phone = data.split(" ")[1];
    // var userId = data.split(" ")[2];
    console.log(data,"TypeAhead Search Name")

    console.log(event,"TypeAhead Search event")
    // const modalRef = this.modal.open(ClinicOpdAppointmentBookingComponent, {
    //   centered: true,
    //   windowClass: 'modal-right hw-40',
    //   backdrop: 'static',
    //   keyboard: false,
    // });
    // modalRef.componentInstance.userId = this.selectedPatient?.userId;

    // modalRef.result.then(
    //   (userConfirmed: boolean) => {
    //     if (userConfirmed) {
    //     }
    //   },
    //   () => { }  // Handle dismiss callback
    // );
    this.openDialog(this.selectedPatient?.userId)
    // this.navigateDataToUserScreen( this.selectedPatient?.userId)
   } else {
     console.log(event,"TypeAhead Search event")
    //  const modalRef = this.modal.open(ClinicOpdAppointmentBookingComponent, {
    //   centered: true,
    //   windowClass: 'modal-right hw-40',
    //   backdrop: 'static',
    //   keyboard: false,
    // });
    // modalRef.componentInstance.userId = this.selectedPatient?.userId;

    // modalRef.result.then(
    //   (userConfirmed: boolean) => {
    //     if (userConfirmed) {
    //     }
    //   },
    //   () => { }  // Handle dismiss callback
    // );
   // this.openDialog(this.selectedPatient?.userId)
  //  this.navigateDataToUserScreen( this.selectedPatient?.userId)
   }

  }
  selectedUserIdFromTypeAhead;
  typeAheadeSearchByName(data) {

    var body = {
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "keywords": data,
      "patientSearch": "NAME"
    }
    this.clinicServices.searchPatientDetails(body).subscribe(res => {
      debounceTime(300), // Wait for 300 ms pause in events
      distinctUntilChanged(), // Ignore if next search term is same as previous
      this.optionsD = [];
      for (var sp of res['data']) {
        // var fullName = sp.firstName + " " + sp.lastName;
        this.selectedUserIdFromTypeAhead = sp.userId;
        // var fullName = sp.patientname + " " + sp.cellNumber + "" + sp.userId
        var fullName = {
          'name' : sp.patientname,
          'number' : sp.cellNumber,
          'userId' : sp.userId,
        };
        // console.log(fullName,"Full name from typeAhead");

        this.optionsD.push(fullName);
      }
      // this.optionsD = this.removeDuplicateItems(this.optionsD);
      this.filteredOptionsD = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterD(value || '')),
      );
      console.log(this.filteredOptionsD,"Filtered Option");

    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }
  displayFn(nationality: any): string {
    // console.log(nationality)
    return nationality && nationality ? nationality : '';
  }
  createUser() {
    this.patientSelected=''
    $('#add-user').modal('show')
  }
  createPatient() {
    const modalRef = this.modal.open(NewpatientComponent,{
      centered: false,
      windowClass : 'right w-60'
    })
    modalRef.componentInstance.selectedOption = this.selectedOption
  }

  hospitalAddress
  getHospitalAddress() {
    this.clinicServices.getHospitalAddress(parseInt(this.globalStorage.getItem('hospitalAddressId'))).subscribe(res => {
      this.hospitalAddress = res['data']
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }
  openDialog(patientId){
    $('#add-user').modal('hide')
    this.closeModal()
    const modalRef = this.modal.open(PatAssignmentComponent,{
      centered: false,
      windowClass : 'modal-right hw-40'
    })
    modalRef.componentInstance.patientId = patientId
    modalRef.result.then(
      (res) => {
        this.selectedOption=res;
        this.navigateDataToUserScreen(patientId)
      })
  }

  naviagte() {
    $('#userFoundConfirmation').modal('hide')
  }
  navigateDataToUserScreen(data) {
    console.log(data,"");

    // if(this.selectedOption=='IPD'){
if (!data) {
  $('#userFoundConfirmation').modal('show')
  return;
}
  var body = {
    "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
    "keywords": data,
    "patientSearch": "NAME"
  }
  this.clinicServices.findUserByHospitalAddressIdAndUserId(parseInt(this.globalStorage.getItem('hospitalAddressId')),data).subscribe(res => {
    var PatDetails = res['data']
   // this.selectedPatient['userId']=PatDetails['userId']
    console.log(PatDetails)
    if(PatDetails?.ipd=='Y'){
      this.toastrService.error('This patient already exists')
      return
    }
    if (PatDetails == null) {
      $('#userFoundConfirmation').modal('show')
    }

    else {
      if (PatDetails['patientReferenceId']) {
        var ptdetails = {
          'firstName':PatDetails['firstName'],
          'patientName': PatDetails['firstName'] + ' ' + PatDetails['lastName'],
          'patientReferenceId': PatDetails['patientReferenceId'],
          'patientType': this.selectedOption,
          // "emailId":res['data']?.['emailId'],
          // "userName":res['data']?.['userName'],
          // 'cellNumber':res['data']?.['cellNumber']
        }
      }else{
      var ptdetails = {
        'firstName':PatDetails['firstName'],
        'patientName': PatDetails['firstName'] + ' ' + PatDetails['lastName'],
        'patientReferenceId': PatDetails['patientReferenceId'],
        'patientType': this.selectedOption,
        // "emailId":res['data']?.['emailId'],
        // "userName":res['data']?.['userName'],
        // 'cellNumber':res['data']?.['cellNumber']
      }}
      const navigationExtras: NavigationExtras = {
        state: {
          patientName: ptdetails
        }
      };
      if(this.selectedOption == 'IPD' || this.selectedOption == 'EMERGENCY'){
        var currentDate = new Date().toJSON();
        var body = {
          "active": 'Y',
          "checkInTime": currentDate,
          "hospitalAddresId": this.globalStorage.getItem('hospitalAddressId'),
          "patientId": PatDetails?.['patientReferenceId'],
          "patientName": PatDetails?.['firstName'] + ' ' + PatDetails?.['lastName']
        }
        this.clinicServices.assinInPatient(body).subscribe(res => {
          ptdetails['inPatientId'] = res['data']
          const navigationExtras: NavigationExtras = {
            state: {
              patientName: ptdetails
            }
          };
          if(this.router.url=='/users-list'){
            this.router.navigate(['/clinic-users'], navigationExtras)
          }
          else{
            this.router.navigate(['/users-list'], navigationExtras)
          }
        },error=>{
          this.toastrService.error(error?.['error']?.['message'])
        })

      }
      else{
        const modalRef = this.modal.open(ClinicOpdAppointmentBookingComponent, {
          centered: false,
          windowClass: 'modal-right hw-40',
          backdrop: 'static',
          keyboard: false,
        });
        var patUserId
        if(PatDetails['patientReferenceId']){
          patUserId=PatDetails['patientReferenceId']
        }
        else{
          patUserId=this.selectedPatient?.userId
        }
        modalRef.componentInstance.userId = patUserId

        modalRef.result.then(
          (res) => {

          },
          () => { }  // Handle dismiss callback
        );
       //this.router.navigate(['/users-list'], navigationExtras)
      }
    }
  }, error => {
    this.toastrService.error(error?.['error']?.['message'])
  })


    //  }
    //   else if(this.selectedOption=='OPD'){
    //     const navigationExtras: NavigationExtras = {
    //       state: {
    //         patientName:ptdetails
    //       }
    //     };
    //     this.router.navigate(['/clinic-users'],navigationExtras)
    //   }
  }
  selectedOption
  isItValid() {
  }
  checkBoxCheck(a, e) {
    if ($('.mat-radio-checked')) {
      a.value.toUpperCase()
      if (a.value == 'IPD') {
        this.selectedOption = a.value
        this.radioMessage='Search & Admit'
      }
      if (a.value == 'OPD') {
        this.selectedOption = a.value
        this.radioMessage='Search & Booking'
      }
      if (a.value == 'EMERGENCY') {
        this.selectedOption = a.value
        this.radioMessage='Search & Admit'
      }
    }
  }

  selectedValue; countryCodeSelected; postalLocations; zip;
  zipEntered() {
    this.selectedValue = null;
    this.countryCodeSelected = null;
  }
  latitude; longitude
  drpdownSelectionForAddress(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }
  percChanged(zip) {
      this.zip = zip;
      this.countryCodeSelected = null;
      this.primaryAddress.patchValue({
        country: '',
        city: '',
        state: '',
        postalLocation: ''
      });
    if(this.zip?.length < 6){
      this.postalLocations = [];
    }
  }
  countryCodeEntered(cc) {
    this.postalLocations = [];
    this.patientService.getPincodeDetails(this.zip, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.primaryAddress.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      // this.latitude = res['result'][0]['latitude'];
      // this.longitude = res['result'][0]['longitude'];
      this.primaryAddress.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      })
    })
  }
  numberOnly1(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnly3(event, phn): boolean {
    console.log('value',event,phn,phn?.value)
    if(phn?.value?.length<10){
      console.log('value',)
      this.isPhoneNumberExists=false
      this.isPhoneNumberExistsMessage=''
    }
    const charCode = (event.which) ? event.which : event.keyCode;

    if (phn.value.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }
  numberOnly(event, phn): boolean {
    console.log('value',event,phn,phn?.value)
    if(phn?.value?.length<10){
      console.log('value',)
      this.isPhoneNumberExists=false
      this.isPhoneNumberExistsMessage=''
    }
    const charCode = (event.which) ? event.which : event.keyCode;

    if (phn.value.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }
  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zåäö ]/i);
    return pattern.test(value);
  };
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z]+$/);
    return pattern.test(value);
  }

  omit_special_char_and_numbers(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }

  invalidCredentials = false; isExist; message
  // checkUserName() {
  //   this.invalidCredentials = false
  //   if (this.f.userName.errors == null) {
  //     this.isExist = false
  //     this.service.userNameExist(this.registerForm.controls['userName'].value).subscribe(res => {
  //       if (res.data == true) {
  //         this.invalidCredentials = true
  //         this.message = res?.message
  //         // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
  //         this.isExist = true
  //         this.submitted = false;
  //         if (this.invalidCredentials == true) {
  //           this.addressDetailsOn = false
  //         }
  //         return
  //       }
  //     }, (error) => {
  //       // this.toastr.error((error['error']['message']))

  //     });
  //   }
  // }
  next(data) {
    // this.checkUserName()
    this.checkEmail()
    this.checkPhoneNumber($('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'])
    if(this.invalidCredentials==true){
      return
    }
    else {
      this.addressDetailsOn = true
    }
  }
  checkEmail() {
    this.invalidCredentials = false
    if (this.f.email.errors == null) {
      this.isExist = false
      this.service.userExist(this.registerForm.controls['email'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          var ema = document.getElementById('directLink').innerHTML;
          $('#directLink').addClass("email");
          this.message = res?.message
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          if (this.invalidCredentials == true) {
            this.addressDetailsOn = false
          }
          return
        }

      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }
  checkForm() {
    if (this.registerForm.value.email  && this.registerForm.value.contactNum && this.registerForm.value.contactNum.length == 10
      && this.registerForm.value.firstName && this.registerForm.value.lastName && (this.registerForm.value.password === this.registerForm.value.confirmPassword)) {
      return false;
    }
    else {
      return true;
    }
  }
  drpdownSelection(item) {
  }

  phNum;
  getNumber(event){
    console.log('event',event)
    this.phNum=event;
  }
  emergencyNum
  getEmrNumber(event){
    this.emergencyNum=event
  }

  isPhoneNumberExists=false;isPhoneNumberExistsMessage;
  checkPhNumber(num) {
    this.isPhoneNumberExists = false;
    this.isPhoneNumberExistsMessage=''
    if (num.length == 10) {
      this.service.checkPhoneNumber(this.phNum.replace(/\+/gi, '%2B')).subscribe(res => {
        if (res?.['data']?.['isExist'] == true) {
          this.isPhoneNumberExists = true;
          this.isPhoneNumberExistsMessage = res?.['message'];
        }
        else {
          this.isPhoneNumberExists = false;
        }
      }, error => {
        this.message = error?.['error']?.['message'];
      });
    }
  }

  checkPhoneNumber(num) {
    this.isPhoneNumberExists = false;
    if (num.length == 10) {
      this.service.checkPhoneNumber(this.phNum.replace(/\+/gi, '%2B')).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          this.message = res?.message
          if (this.invalidCredentials == true) {
            this.addressDetailsOn = false
          }
          return
        }
      }, (error) => {
        this.checkForm();
        this.message = error['error']['message'];
      });
    }
  }

  patientDetails
  onSubmit(form) {
    const date = formatDate(this.registerForm.value.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    var body = {
      "cellNumber": "+91" + this.registerForm.value.contactNum,
      "emailId": this.registerForm.value.email,
      "firstName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1),
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "hospitalUserId": this.globalStorage.getItem('forUserId'),
      "lastName": this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
      "patientName": this.registerForm.value.firstName + " " + this.registerForm.value.lastName,
      "userName": this.registerForm.value.contactNum
    }
    var reqBody = {
      "active": "Y",
      "address": {
        "active": "Y",
        "city": this.primaryAddress.value.city,
        "contactName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1) + " " + this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
        "country": this.primaryAddress.value.country,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "postalLocation": this.primaryAddress.value.postalLocation,
        "state": this.primaryAddress.value.state,
        "street1": this.primaryAddress.value.street1,
        "street2": this.primaryAddress.value.street2,
        "zip": this.primaryAddress.value.zip,
      },
      "cellNumber": "+91" + this.registerForm.value.contactNum,
      "emergenyContactNumber":this.emergencyNum,
      "emailId": this.registerForm.value.email,
      "firstName": this.registerForm.value.firstName,
      "password": this.registerForm.value.password,
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "emergencyContact": this.registerForm.value.emergencyContactName,
      "lastName": this.registerForm.value.lastName,
      "patientName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1) + " " + this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
      "personaldetail": {
        "bloodgroup": this.registerForm.value.bloodgroup,
        "dob": date.concat('Z'),
        "ethinicity": " ",
        "eyeColor": " ",
        "gender": this.registerForm.value.gender,
        "height": " ",
        "highestDegree": " ",
        "martialstatus": " ",
        "nationality": " ",
        "preferredlanguages": " "
      },
      "userName": this.registerForm.value.contactNum
    }
    this.clinicServices.saveUser(reqBody).subscribe(res => {
      this.patientDetails = res['data']
      this.toastrService.success('User added successfully.')
      $('#add-user').modal('hide')
      this.primaryAddress.reset()
      this.registerForm.reset()
      this.selectedValue3=null
      this.selectedValue4=null
      this.addressDetailsOn = false
      this.submitted = false
      this.userOption=[]
      this.userFound=false
      this.createNewUser=false
      this.searchPhoneNum=''
      this.messageOpen=false
      this.searchphNum=true
      console.log(res);

      this.openDialog(res['data']['patientReferenceId'])
      return

      if (this.selectedOption == 'IPD' || this.selectedOption == 'EMERGENCY') {
        this.navigateDataToUserScreen(res['data']['patientReferenceId'])
      }
      if (this.selectedOption == 'OPD') {
        const modalRef = this.modal.open(ClinicOpdAppointmentBookingComponent, {
          centered: false,
          windowClass: 'modal-right hw-40',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.userId = this.patientDetails?.patientReferenceId;

        modalRef.result.then(
          (userConfirmed: boolean) => {
            if (userConfirmed) {
            }
          },
          () => { }  // Handle dismiss callback
        );
        // this.navigateDataToUserScreen(res['data']['patientReferenceId'])
      }
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }

  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }
// Notifications Functions
  updateNotify(data) {
    this.notifyToView = data;
    $('#notificationToView').modal('show');
    // $('#viewreminders').modal('hide');
    var req = {
      "active": data.active,
      "hospitalId": data.hospitalId,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "reason": data.reason,
      "viewed": "Y"
    }

    this.clinicServices.updateNotifications(data.notificationalertsId, req).subscribe(res => {
      this.getNotifications();
      this.notificationLength = this.notificationLength - 1;
    }, error => {
      this.toastrService.error(error?.['error']?.['message']);
    })
  }

  closeNotificationViewerModal() {
    $('#notificationToView').modal('hide');
    $("#notificationToView").val(null).trigger("change");
    $('#viewreminders').modal('hide');
    this.getNotifications();
    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
  }
  createMessage(){
    const message = this.modal.open(SendMessageComponent, {
      centered: false,
      windowClass: 'modal-right hw-40',
      backdrop: 'static',
      keyboard: false,
    });
    message.componentInstance.addressId=parseInt(this.globalStorage.getItem('hospitalAddressId'))
  }
  requestList
  getRequestList(){
    const modalRef = this.modal.open(DiscountRequestsComponent, {
      centered: false,
      windowClass: 'modal-right hw-40',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.requestList = this.requestList;

    modalRef.result.then(
      (userConfirmed: boolean) => {
        if (userConfirmed) {
        }
      },
      () => { }  // Handle dismiss callback
    );
  }
  navigateToRelatedDashboard(){
    if(this.loginUser?.roleId==1){
      this.router.navigate(['/clinic-dashboard'])
    }
    if(this.loginUser?.roleId==2){
      this.router.navigate(['/clinic-doctor-dashboard'])
    }
    if(this.loginUser?.roleId==10){
      this.router.navigate(['/clinic-nurse-dashboard'])
    }
    if(this.loginUser?.roleId==11){
      this.router.navigate(['/clinic-dashboard'])
    }
  }
  getMessages(){
    const modalRef = this.modal.open(InternalMessageComponent, {
      centered: false,
      windowClass: 'modal-right hw-30',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.addressId = parseInt(this.globalStorage.getItem('hospitalAddressId'))
  }
  userList;userFound=false;messageOpen=false;searchingPhnum;
  searchPatientByPhone(searchPhoneNum){
    var number = $('.iti__selected-dial-code').html()
    console.log('ph num  ',searchPhoneNum,this.myControlUser.value?.length)
    var body={
      "keywords": number+this.myControlUser.value,
      "patientSearch": "CELLNUMBER"
    }
    this.searchingPhnum= number+this.myControlUser.value
    if(this.myControlUser.value?.length==0){
      this.messageOpen=false
    }
    if(this.myControlUser.value?.length>=9){
      this.clinicServices.searchUserByNumber(body).subscribe(res=>{
        this.userList=res['data']
        this.userOption = [];
        if(this.userList?.length==0){
          this.messageOpen=true
        }
        else{
          this.messageOpen=false
          for (var sp of res['data']) {
            var fullName = {
              'name' : sp.patientname,
              'number' : sp.cellNumber,
              'userId' : sp.userId,
              'emailId':sp.emailId,
              'firstName':sp.firstName,
              'lastName':sp.lastName,
              'dob':sp.dob,
              'gender':sp.gender,
              'userName':sp.userName
            };
            // console.log(fullName,"Full name from typeAhead");
            this.userOption.push(fullName);
          }
          this.filteredUsers = this.myControlUser.valueChanges.pipe(
            startWith(''),
            map(value => this._filterU(number+this.myControlUser.value || '')),
          );
        }
        console.log('filteredUsers',this.filteredUsers)
      },error=>{
        this.toastr.error(error?.error?.message)
      })
    }
  }
  extractPhoneNumberWithoutCountryCode(phoneNumber) {
    // Remove the '+' sign and the first digit (country code)
    return phoneNumber?.replace(/^\+/, '')?.slice(2);
  }
  patientInfo
  selectedOne(data){
    console.log('user select',data)
    if(this.searchingPhnum){
      this.clinicServices.getPatientInfo(this.globalStorage.getItem('hospitalAddressId'),this.searchingPhnum?.replace(/\+/gi, '%2B')).subscribe(res=>{
        console.log('search user res',res)
        this.patientInfo=res['body']?.['data']?.['primaryUserDTO']
        if(this.patientInfo?.['isUserExit']=='Y'){
          this.openDialog(this.patientInfo?.['userId'])
          return
        }
        else{
          const dob=formatDate(data?.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us')
          this.userFound=true
          this.userFoundForm.patchValue({
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.emailId,
            contactNum: data?.number,
            dob:dob,
            gender:data?.gender,
            userName:data?.userName,
            userId:data?.userId
          })
        }
      },error=>{
        this.toastr.error(error?.error?.message)
      })
    }
  }
  addUser(){
    const date = formatDate(this.userFoundForm.value.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var reqBody = {
      "active": "Y",
      "address": {
        "active": "Y",
        "city": '',
        "contactName": this.userFoundForm.value.firstName.charAt(0).toUpperCase()+this.userFoundForm.value.firstName.slice(1) + " " + this.userFoundForm.value.lastName.charAt(0).toUpperCase()+this.userFoundForm.value.lastName.slice(1),
        "country": '',
        "latitude": this.latitude,
        "longitude": this.longitude,
        "postalLocation": '',
        "state": '',
        "street1":'',
        "street2": '',
        "zip": '',
      },
      "cellNumber": this.userFoundForm.value.contactNum,
      "emergenyContactNumber":'',
      "emailId": this.userFoundForm.value.email,
      "firstName": this.userFoundForm.value.firstName.charAt(0).toUpperCase()+this.userFoundForm.value.firstName.slice(1),
      "patientReferenceId":this.userFoundForm.value.userId,
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "emergencyContact": '',
      "lastName": this.userFoundForm.value.lastName.charAt(0).toUpperCase()+this.userFoundForm.value.lastName.slice(1),
      "patientName": this.userFoundForm.value.firstName.charAt(0).toUpperCase()+this.userFoundForm.value.firstName.slice(1) + " " + this.userFoundForm.value.lastName.charAt(0).toUpperCase()+this.userFoundForm.value.lastName.slice(1),
      "personaldetail": {
        "bloodgroup": '',
        "dob": date.concat('Z'),
        "ethinicity": " ",
        "eyeColor": " ",
        "gender": this.userFoundForm.value.gender,
        "height": " ",
        "highestDegree": " ",
        "martialstatus": " ",
        "nationality": " ",
        "preferredlanguages": " "
      },
      "userName": this.userFoundForm.value.userName
    }
    this.clinicServices.saveUser(reqBody).subscribe(res => {
      this.patientDetails = res['data']
      $('#add-user').modal('hide')
      this.primaryAddress.reset()
      this.registerForm.reset()
      this.selectedValue3=null
      this.selectedValue4=null
      this.addressDetailsOn = false
      this.submitted = false
      this.userOption=[]
      this.userFound=false
      this.createNewUser=false
      this.searchPhoneNum=''
      this.messageOpen=false
      this.searchphNum=true
      console.log(res);
      if(res['data']['flag']=='YES'){
        this.openDialog(this.userFoundForm.value.userId)
        return
      }
      else{
        this.toastrService.success('User added successfully.')
        this.openDialog(res['data']['patientReferenceId'])
        return
      }
     

      if (this.selectedOption == 'IPD' || this.selectedOption == 'EMERGENCY') {
        this.navigateDataToUserScreen(res['data']['patientReferenceId'])
      }
      if (this.selectedOption == 'OPD') {
        const modalRef = this.modal.open(ClinicOpdAppointmentBookingComponent, {
          centered: false,
          windowClass: 'modal-right hw-40',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.userId = this.patientDetails?.patientReferenceId;

        modalRef.result.then(
          (userConfirmed: boolean) => {
            if (userConfirmed) {
            }
          },
          () => { }  // Handle dismiss callback
        );
        // this.navigateDataToUserScreen(res['data']['patientReferenceId'])
      }
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }
  userNotExistConfirmationYes(){
    this.createNewUser=true
    this.messageOpen=false
    this.searchphNum=false
  }
  userNotExistConfirmationNo(){
    this.messageOpen=false
  }
  removeModal(){
    $("body").removeClass("modal-open")
  }
  file
  onSend(data) {
    if (!data.description) {
      this.toastr.warning('Please enter description')
      return
    }
    var formData = new FormData();
    formData.append('screenshot', data.screenshot);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    var request = {
      "clientId": 12,
      "userId": parseInt(this.globalStorage.getItem('hospitalUserId')),
      "feedBackType": "TEST",
      "status": "NEW",
      "description": data.description,
      "userAgent": "test",
      "os": "Windows",
      "browser": "Chrome",
      "os_version": "windows-10",
      "browser_version": "85.0.4183.102",
      "device": "Unknown",
      "latitude": this.gps.latitude,
      "longitudes": this.gps.longitude,
      "feedbackTime": formatDate(dateTime, "yyyy-MM-dd", 'en-us')
    }
    formData.append('feedbackLoop', JSON.stringify(request));
    this.clinicServices.sendFeedback(formData).subscribe(res => {
      this.toastr.success('Thanks for your submission we will get back to you soon.')
    }, error => {
      this.toastr.error(error['error']['message'])
    })
  }
}
