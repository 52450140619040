// export const environment = {
//   production: true
// };
export const environment = {
  production: true,
  billdeskSDK: {
    css: 'https://pay.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.css',
    esmJs: 'https://pay.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.esm.js',
    js: 'https://pay.billdesk.com/jssdk/v1/dist/billdesksdk.js'
  },
  // firebase: {
  //   apiKey: "AIzaSyCdFjJGGBb-BD_PdBnIwHFyRKGKTtUVbsQ",
  //   authDomain: "jatintest-7bfa6.firebaseapp.com",
  //   databaseURL: "https://jatintest-7bfa6.firebaseio.com",
  //   projectId: "jatintest-7bfa6",
  //   storageBucket: "jatintest-7bfa6.appspot.com",
  //   messagingSenderId: "186495523900",
  //   appId: "1:186495523900:web:8a5a5855063a2e035ab739",
  //   measurementId: "G-YBMB8332CR"
  // },
  firebase:{
    apiKey: "AIzaSyBoaV2yUMP13YZEnf_4wUEAj7KsTn9QE50",
    authDomain: "curemetric-6dedb.firebaseapp.com",
    projectId: "curemetric-6dedb",
    storageBucket: "curemetric-6dedb.appspot.com",
    messagingSenderId: "449279881832",
    appId: "1:449279881832:web:c8285fe81013a09bacc1dd",
    measurementId: "G-5GPBLK9RS7"
  },
  //doctorBaseUrl: 'https://147.135.223.84/api/doctor/',
 //patientBaseUrl:'https://147.135.223.84/api/patient/',
//pharmaUrl:"https://147.135.223.84/api/pharmacy/",
 //diagnosticBaseUrl:"https://147.135.223.84/api/diagnosticcenter/"
doctorBaseUrl: '/api/doctor/',
  patientBaseUrl: '/api/patient/',
  pharmaUrl: "/api/pharmacy/",
  diagnosticBaseUrl: "/api/diagnosticcenter/",
  caretakerUrl: "/api/caretaker/",
  nutritionistUrl: "/api/nutritionist/",
  elesticSearchUrl: "/api/elasticsearch/",
  utilitiesUrl: "/api/utilities/",
  hospitalUrl: "/api/hospital/",
  rpmAdminUrl: "/api/rpmadmin/",
  ivfUrl:'/api/ivf/',
  pdfUrl: 'https://qa.curemetric.com/api/pdf-generator/',
  doctorBaseUrl1: '/api/doctor/',
  patientBaseUrl1: '/api/patient/',
  pharmaUrl1: "/api/pharmacy/",
  diagnosticBaseUrl1: "/api/diagnosticcenter/",
  caretakerUrl1: "/api/caretaker/",
  nutritionistUrl1: "/api/nutritionist/",
  elesticSearchUrl1: "/api/elasticsearch/",
  utilitiesUrl1: "/api/utilities/",
  hospitalUrl1: "/api/hospital/",
  rpmAdminUrl1: "/api/rpmadmin/",
  adminUrl : '/api/superadmin/',
  adminUrl1 : '/api/superadmin/',
  ivfUrl1:'/api/ivf/',
  pdfUrl1: 'https://qa.curemetric.com/api/pdf-generator/'

  };
