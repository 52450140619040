import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { YearlyInstance } from 'twilio/lib/rest/api/v2010/account/usage/record/yearly';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApicallsService {

  isUserSessionExits;
  ismoduleNameExits;
  isforUserIdExits;
  headers;

  constructor(private globalStorage: GlobalStorageService, private httpClient: HttpClient) {
    this.subjects = new Map<string, Subject<any>>();
  }

  userSession: string = this.globalStorage.getItem('userSession')
  windowReload = new BehaviorSubject<boolean>(false);
  windowReload$ = this.windowReload.asObservable();

  setReload(val) {
    if (val) {
      // this.globalStorage.loadFromLocalStorage()
    } else {
      this.globalStorage.saveToLocalStorage()
    }
    this.windowReload.next(val);
  }
  subJson; subJson2
  subjects: Map<string, Subject<any>> = null;
  postRes;
  // var decode=atob(res.body.data)
  // var actualData=atob(decode)
  convertBase64(res) {
    res.subscribe(data => this.postRes = data)
    // console.log('base 64 data',this.postRes)
    // return atob(this.postRes)
  }
  post(url: string, body: any, options: any, skipLoader?: boolean): any {

    const userObject = {
      "moduleName": this.globalStorage.getItem('loggedInAs'),
      "referenceId": parseInt(this.globalStorage.getItem('entityId')) || null,
      "userId": parseInt(this.globalStorage.getItem('userId')) || null,
    }
    if (this.globalStorage.getItem('loggedInAs') === "PATIENT" || this.globalStorage.getItem('loggedInAs') === "CARETAKER") {
      userObject['forUserId'] = this.globalStorage.getItem('forUserId') || null
    }
    let headersConfig = {
      "user": this.encryptObjectToBase64(userObject),
      'Cache-Control': 'no-store, no-cache',
    };
    if (skipLoader) {
      headersConfig['skipLoader'] = 'true';
    }
    const userSession = this.globalStorage.getItem('userSession');
    if (userSession) {
      headersConfig['user-session'] = JSON.parse(userSession);
    }

    const newOptions = {
      headers: new HttpHeaders(headersConfig)
    }

    if (options?.params) {
      newOptions['params'] = options.params;
    }
    if (options?.responseType) {
      newOptions['responseType'] = options.responseType;
    }
    if (options.observe != undefined) {
      newOptions['observe'] = options.observe;
    } if (url.includes('medicalhistorycontroller/save')) {
      return this.httpClient.post(url, body, newOptions).pipe(delay(30))
    }

    if (url.includes('login') ||
      url.includes(environment.elesticSearchUrl) || url.includes(environment.utilitiesUrl)) {
      //  var encoded = btoa(JSON.stringify(body))
      //   console.log('base 64 data',this.postRes)
      //  console.log('gettte',this.convertBase64(this.httpClient.post(url, body, options).pipe(delay(30))))
      return this.httpClient.post(url, body, newOptions).pipe(delay(30))
      //  this.httpClient.post(url, encoded, options).pipe(delay(30)).subscribe(res=>{
      //   console.log('encode decode res',res)
      //   this.subJson=res
      //   if(res['body']){
      //     this.subJson['body']['data']=JSON.parse(atob(this.subJson['body']['data']))
      //   }
      //   else{
      //     this.subJson['data']=JSON.parse(atob(this.subJson['data']))
      //   }
      //   console.log('encode decode',this.subJson,this.subJson['body']['data'])
      //  })
      //   let observable=Observable.create(observer => {
      //   setTimeout(() => {
      //     observer.next(this.subJson);
      //     observer.complete(); // to show we are done with our processing
      //    // observer.error(new Error("error message"));
      //   }, 2000);
      // })
      // return observable
    } else {
      // var encoded = btoa(JSON.stringify(body))
      //  console.log('post res',this.httpClient.post(url, body, newOptions).pipe(delay(30)))
      // this.convertBase64(this.httpClient.post(url, body, options).pipe(delay(30)))
      return this.httpClient.post(url, body, newOptions).pipe(delay(30))
      //   this.httpClient.post(url, encoded, newOptions).subscribe(res=>{
      //     console.log('encode decode res',res)
      //     this.subJson2=res
      //     if(res['body']!=undefined || res['body']!=null){
      //       this.subJson2['body']['data']=JSON.parse(atob(this.subJson2['body']['data']))
      //     }
      //     if(res['data']!=undefined || res['data']!=null){
      //       this.subJson2['data']=JSON.parse(atob(this.subJson2['data']))
      //     }
      //   console.log('encode decode',url,this.subJson2)
      //  })
      //   let observable2=Observable.create(observer => {
      //    setTimeout(() => {
      //     observer.next(this.subJson2);
      //     observer.complete(); // to show we are done with our processing
      //    // observer.error(new Error("error message"));
      //   }, 700);

      // })
      // return observable2
    }
    // return this.httpClient.post(url, body, options).pipe(delay(30));
  }
  //isEmailRegistered
  get(url: string, options: any, skipLoader?: boolean): any {
    const userObject = {
      "moduleName": this.globalStorage.getItem('loggedInAs'),
      "referenceId": parseInt(this.globalStorage.getItem('entityId')) || null,
      "userId": parseInt(this.globalStorage.getItem('userId')) || null,
    }
    if (this.globalStorage.getItem('loggedInAs') === "PATIENT" || this.globalStorage.getItem('loggedInAs') === "CARETAKER") {
      userObject['forUserId'] = this.globalStorage.getItem('forUserId') || null
    }
    let headersConfig = {
      "user": this.encryptObjectToBase64(userObject),
      'Cache-Control': 'no-store, no-cache',
    };
    const userSession = this.globalStorage.getItem('userSession');
    if (userSession) {
      headersConfig['user-session'] = JSON.parse(userSession);
    }
    if (skipLoader) {
      headersConfig['skipLoader'] = 'true';
    }

    const newOptions = {
      headers: new HttpHeaders(headersConfig)
    }

    if (options?.params) {
      newOptions['params'] = options.params;
    }

    if (options?.responseType) {
      newOptions['responseType'] = options.responseType;
    }
    if (options.observe != undefined) {
      newOptions['observe'] = options.observe;
    }
    if (url.includes(environment.elesticSearchUrl) || url.includes(environment.utilitiesUrl)
      // || url.includes('https://api.worldpostallocations.com') || url.includes('userEmailRegistered')
      // || url.includes('userphonenumberRegistered') || url.includes('userNameExists')
      // || url.includes('password/reset') || url.includes('verification/phoneotp?cellNumber=')
      // || url.includes('iscareTakerNameExit') || url.includes('verify/emailId/exists?')
      // || url.includes('phoneVerificationNew?cellNumber=')
      // || url.includes('iscaretaker/phonenumberexit') || url.includes('lookupstatic')
      // || url.includes('phone/verification') || url.includes('email/verification')
      // || url.includes('reset/password') || url.includes('https://api.worldpostallocations.com/pincode')
      // || url.includes('resendOTP') || url.includes('emailOtpVerification') || url.includes('loginWithPin?cellNumber=')
      // || url.includes('inventoryController/inventory')
      // || url.includes('verify/email/') || url.includes('isuserNameExists')
      // || url.includes('doctorNameExists') || url.includes('otpverification?emailId=')
      // || url.includes('verifyphoneNumberExists')
      // || url.includes('get/doctor/byemailid')
      // || url.includes('validate/phone')
      // || url.includes('validate/email') || url.includes('emailVerification')
      // || url.includes('phoneverification')
      // || url.includes('isuserNameExistOrNot')
      // || url.includes('requestOTPForEmail')
      // || url.includes('otpverification')
      // || url.includes('otpVerificationForEmail') || url.includes('requestOtpForPhone')
      // || url.includes('verification')
      // || url.includes('getUserDetailsByEmail')
      // || url.includes('medicine/findmedicineByName')
      // || url.includes('requestOTPForEamilForDiagnosticcenterUser')
      // || url.includes('emailVerificationForDiagnosticcenterUser')
      // || url.includes('requestOTPForPhone')
      // || url.includes('requestOtpForPhone')
      // || url.includes('otp/verification')
      // || url.includes('verify/name')
      // || url.includes('resendOTPForEmail')

    ) {
      return this.httpClient.get(url, options
      ).pipe(delay(30))
      // return this.httpClient.get(url, options
      // ).pipe(delay(30)).subscribe(res=>{
      //   console.log('encode decode get res',res)
      //   })
    } else {
      return this.httpClient.get(url, newOptions).pipe(delay(30))
      //   this.httpClient.get(url, newOptions
      //     ).pipe(delay(30)).subscribe(res=>{
      //     console.log('encode decode res',res)
      //     this.subJson2=res
      //     if(res['body']){
      //       this.subJson2['body']['data']=JSON.parse(atob(this.subJson2['body']['data']))
      //     }
      //     else{
      //       this.subJson2['data']=JSON.parse(atob(this.subJson2['data']))
      //     }
      //   console.log('encode decode',this.subJson2)
      //  })
      //   let observable2=Observable.create(observer => {
      //   setTimeout(() => {
      //     observer.next(this.subJson2);
      //     observer.complete(); // to show we are done with our processing
      //    // observer.error(new Error("error message"));
      //   }, 800);
      // })
      // return observable2
    }
  }

  put(url: string, body: any, options: any): any {
    const userObject = {
      "moduleName": this.globalStorage.getItem('loggedInAs'),
      "referenceId": parseInt(this.globalStorage.getItem('entityId')) || null,
      "userId": parseInt(this.globalStorage.getItem('userId')) || null,
    }
    if (this.globalStorage.getItem('loggedInAs') === "PATIENT" || this.globalStorage.getItem('loggedInAs') === "CARETAKER") {
      userObject['forUserId'] = this.globalStorage.getItem('forUserId') || null
    }
    let headersConfig = {
      "user": this.encryptObjectToBase64(userObject),
      'Cache-Control': 'no-store, no-cache',
    };
    const userSession = this.globalStorage.getItem('userSession');
    if (userSession) {
      headersConfig['user-session'] = JSON.parse(userSession);
    }

    const newOptions = {
      headers: new HttpHeaders(headersConfig)
    }

    if (options?.params) {
      newOptions['params'] = options.params;
    }
    if (options?.responseType) {
      newOptions['responseType'] = options.responseType;
    }
    if (options.observe != undefined) {
      newOptions['observe'] = options.observe;
    }

    if (url.includes(environment.elesticSearchUrl) || url.includes(environment.utilitiesUrl)) {
      return this.httpClient.put(url, body, options).pipe(delay(30))
    } else {
      return this.httpClient.put(url, body, newOptions).pipe(delay(30))
    }

  }

  delete(url: string, options: any): any {
    const userObject = {
      "moduleName": this.globalStorage.getItem('loggedInAs'),
      "referenceId": parseInt(this.globalStorage.getItem('entityId')) || null,
      "userId": parseInt(this.globalStorage.getItem('userId')) || null,
    }
    if (this.globalStorage.getItem('loggedInAs') === "PATIENT" || this.globalStorage.getItem('loggedInAs') === "CARETAKER") {
      userObject['forUserId'] = this.globalStorage.getItem('forUserId') || null
    }
    let headersConfig = {
      "user": this.encryptObjectToBase64(userObject),
      'Cache-Control': 'no-store, no-cache',
    };
    const userSession = this.globalStorage.getItem('userSession');
    if (userSession) {
      headersConfig['user-session'] = JSON.parse(userSession);
    }

    const newOptions = {
      headers: new HttpHeaders(headersConfig)
    }
    if (options?.responseType) {
      newOptions['responseType'] = options.responseType;
    }
    if (options?.params) {
      newOptions['params'] = options.params;
    }

    // const newOptions = {
    //   headers: new HttpHeaders(
    //     {
    //       'user-session': JSON.parse(this.globalStorage.getItem('userSession')),
    //       "user":this.encryptObjectToBase64(userObject),
    //       // "forUserId": this.globalStorage.getItem('forUserId'),
    //       // "userId":this.globalStorage.getItem('userId'),
    //       // "moduleName": this.globalStorage.getItem('loggedInAs'),
    //       'Cache-Control': 'no-store, no-cache',
    //       // 'Content-Type': 'application/json'
    //     })
    // }

    if (options.observe != undefined) {
      newOptions['observe'] = options.observe;
    }

    if (url.includes(environment.elesticSearchUrl) || url.includes(environment.utilitiesUrl)) {
      return this.httpClient.delete(url, options
      ).pipe(delay(30));
    } else {
      return this.httpClient.delete(url, newOptions
      ).pipe(delay(30));
    }

  }

  encryptObjectToBase64(obj: any): string {
    const jsonString = JSON.stringify(obj);
    return btoa(jsonString);
  }
}
