import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { NavBarComponent } from 'src/app/shared/components/nav-bar/nav-bar.component';
import { AuthLayoutRoutes } from '../layout/auth-layout/auth-layout.routing';
import { AuthService } from './services/auth.service';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { docNavBarComponent } from './DocComponents/nav-bar/nav-bar.component';
import { docSidebarComponent } from './DocComponents/sidebar/sidebar.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoaderComponent } from './DocComponents/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { ChangePasswordComponent } from './DocComponents/change-password/change-password.component';
import { TimeFormat } from 'src/app/_helpers/utility.';
import { ViewRemindersComponent } from '../patient-pages/view-reminders/view-reminders.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CameraComponent } from './patient-video-room-utilities/camera/camera.component';
import { DeviceSelectComponent } from './patient-video-room-utilities/settings/device-select.component';
import { SettingsComponent } from './patient-video-room-utilities/settings/settings.component';
import { RoomsComponent } from './patient-video-room-utilities/rooms/rooms.component';
import { ParticipantsComponent } from './patient-video-room-utilities/participants/participants.component';
import { DoctorCamerasComponent } from './doctor-video-room-utilities/doctor-cameras/doctor-cameras.component';
import { DoctorSettingsComponent } from './doctor-video-room-utilities/doctor-settings/doctor-settings.component';
import { DoctorParticipantsComponent } from './doctor-video-room-utilities/doctor-participants/doctor-participants.component';
import { DoctorRoomsComponent } from './doctor-video-room-utilities/doctor-rooms/doctor-rooms.component';
import { doctorPatientComponent } from '../shared/patient-video-room-utilities/patient-consulting-Room/doctor-patient-room.component';
import { ViewDoctorRemaindersComponent } from '../DoctorPages/view-doctor-remainders/view-doctor-remainders.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { ViewRatingsComponent } from '../DoctorSideNavePages/view-ratings/view-ratings.component';
import { InfoComponentComponent } from './info-component/info-component.component';
import { AfterRegisterComponent } from './doctor-redirection-pages/after-register.component';
import { CancelPatientAppointmentComponent } from './components/cancel-patient-appointment/cancel-patient-appointment.component';

import { AgePipe } from '../_helpers/age.pipe';
import { MatDatepickerModule, MatFormFieldModule } from '@angular/material';
import { DemoNumber } from '../_helpers/numbertoarray.pipe';
import { SplitPipe } from '../_helpers/split.pipe';
import { Split2Pipe } from '../_helpers/split2.pipe';
import { TruncatePipe } from '../_helpers/truncate.pipe';
import { SearchPipe } from '../_helpers/search.pipe';
import { PharmacyCreatePasswordComponent } from '../pharmacy-pages/pharmacy-create-password/pharmacy-create-password.component';
import { DiagnosticSidebarComponent } from './diagnostic-components/diagnostic-sidebar/diagnostic-sidebar.component';
import { DiagnosticRedirectPagesComponent } from './diagnostic-redirect-pages/diagnostic-redirect-pages.component';
import { PharmacyRedirectPagesComponent } from './pharmacy-redirect-pages/pharmacy-redirect-pages.component';
import { CaretakerRedirectPagesComponent } from './caretaker-redirect-pages/caretaker-redirect-pages.component';
import { FeedbackModule } from 'ng-feedback';
import { LoginFooterComponent } from './components/login-footer/login-footer.component';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
import { AppointmentServices } from './services/appointment.service';
import { AddVitalsComponent } from './components/add-vitals/add-vitals.component';
import { VitalsService } from './components/add-vitals/vitals.service';
import { PrescriptionScreenComponent } from './components/prescription-screen/prescription-screen.component';
import { CommonForgotPasswordScreenComponent } from './components/common-forgot-password-screen/common-forgot-password-screen.component';
import { CommonRegistrationScreenComponent } from './components/common-registration-screen/common-registration-screen.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {MatInputModule} from '@angular/material/input';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { FormatTime } from '../_helpers/format.pipe';
import { NumberToWordsPipe } from '../_helpers/numberToWords.pipe';
import { LetterOnlyPipe } from '../_helpers/letter.pipe';
import { UserVitalsComponent } from '../common-components/user-vitals/user-vitals.component';
import { ViewPrescriptionsModalComponent } from '../commonComponents/view-prescriptions-modal/view-prescriptions-modal.component';
import { DisplayPrescriptionModalComponent } from '../commonComponents/display-prescription-modal/display-prescription-modal.component';
import { ViewPrescriptionComponent } from '../DoctorSideNavePages/view-prescription/view-prescription.component';
import { UnifiedValidationDirective } from '../unified-validation.directive';
import { InputRestrictDirective } from '../input-restrict.directive';
import { ConvetToWordsPipe } from '../pipes/convet-to-words.pipe';
import { UserVitalsHistoryComponent } from '../common-components/user-vitals-history/user-vitals-history.component';
import { ClinicOpdAppointmentBookingComponent } from './components/clinic-opd-appointment-booking/clinic-opd-appointment-booking.component';
import { AppointmentBookingComponentNew } from '../common-components/appointment/appointment-booking/appointment-booking.component';
import { DoctorScheduleComponent } from './components/doctor-schedule/doctor-schedule.component';
import { PaymentInfoComponent } from '../common-components/appointment/payment-info/payment-info.component';
import { PaymentInformationScreenComponent } from './components/payment-information-screen/payment-information-screen.component';
import { ConsentFormComponent } from './components/consent-form/consent-form.component';
import { BookingConfirmScreenComponent } from './components/booking-confirm-screen/booking-confirm-screen.component';
import { DiscountDefinitionComponent } from './components/discount-definition/discount-definition.component';
import { DiscountRequestsComponent } from './components/discount-requests/discount-requests.component';
import { PatientDetailsEditScreenComponent } from './components/patient-details-edit-screen/patient-details-edit-screen.component';
import { PatientPrimaryDetailsScreenComponent } from './components/patient-primary-details-screen/patient-primary-details-screen.component';
import { PatientPersonalDetailsScreenComponent } from './components/patient-personal-details-screen/patient-personal-details-screen.component';
import { DcReportComponent } from './components/dc-report/dc-report.component';
import { AppointmentDetailsComponent } from './components/appointment-details/appointment-details.component';
import { CommonPrescriptionComponent } from './components/common-prescription/common-prescription.component';
import { NewPharmaOrderComponent } from '../pharmacy-pages/new-pharma-order/new-pharma-order.component';
import { PatAssignmentComponent } from './models/pat-assignment/pat-assignment.component';
import { PatientMedicalHistoryHolderComponent } from './components/patient-medical-history-holder/patient-medical-history-holder.component';
import { PharmaPaymentComponent } from '../pharmacy-pages/pharma-payment/pharma-payment.component';
import { AddCategoryComponent } from '../commonComponents/inventory/add-category/add-category.component';
import { AddManufacturerComponent } from '../commonComponents/modals/add-manufacturer/add-manufacturer.component';
import { AllSuppliersOfIndividualCategoryComponent } from '../commonComponents/all-suppliers-of-individual-category/all-suppliers-of-individual-category.component';
import { AddSubCategoryComponent } from '../commonComponents/inventory/add-sub-category/add-sub-category.component';
import { AddSupplierComponent } from '../commonComponents/inventory/add-supplier/add-supplier.component';
import { AssignSupplierComponent } from '../commonComponents/inventory/assign-supplier/assign-supplier.component';
import { CreateOrdersComponent } from '../commonComponents/inventory/create-orders/create-orders.component';
import { InventoryBatchsComponent } from '../commonComponents/inventory/inventory-batchs/inventory-batchs.component';
import { OrderDeliveryComponent } from '../commonComponents/inventory/order-delivery/order-delivery.component';
import { AddSubcatAtSupplieritemsComponent } from '../commonComponents/modals/add-subcat-at-supplieritems/add-subcat-at-supplieritems.component';
import { BulkOrderDeliveryComponent } from '../commonComponents/modals/bulk-order-delivery/bulk-order-delivery.component';
import { CurrentBatchInfoComponent } from '../commonComponents/modals/current-batch-info/current-batch-info.component';
import { CurrentorPreviousInvOrdersComponent } from '../commonComponents/modals/currentor-previous-inv-orders/currentor-previous-inv-orders.component';
import { OrderBatchInformationComponent } from '../commonComponents/modals/order-batch-information/order-batch-information.component';
import { OrderDispatchComponent } from '../commonComponents/modals/order-dispatch/order-dispatch.component';
import { SaveBulkOrderComponent } from '../commonComponents/modals/save-bulk-order/save-bulk-order.component';
import { SaveOrderDispatchComponent } from '../commonComponents/modals/save-order-dispatch/save-order-dispatch.component';
import { SaveSubcategoryComponent } from '../commonComponents/modals/save-subcategory/save-subcategory.component';
import { SaveSupplierComponent } from '../commonComponents/modals/save-supplier/save-supplier.component';
import { ViewSupplierInfoComponent } from '../commonComponents/modals/view-supplier-info/view-supplier-info.component';
import { SingleSupplierInfoComponent } from '../commonComponents/single-supplier-info/single-supplier-info.component';
import { AutoResizeRowsDirective } from 'projects/ivf/src/app/directives/auto-resize-rows.directive';
import { AssignRolesComponent } from '../commonComponents/assign-roles/assign-roles.component';
import { DiscountRequestInfoComponent } from './components/discount-request-info/discount-request-info.component';
import { SupplierOrderInvoiceComponent } from '../commonComponents/modals/supplier-order-invoice/supplier-order-invoice.component';
import { GenericReportsComponent } from './generic-reports/generic-reports.component';
import { SendMessageComponent } from '../commonComponents/internal-message/send-message/send-message.component';
import { TemplateFormComponent } from './components/template-form/template-form.component';
import { PlaceOrderComponent } from '../commonComponents/inventory/place-order/place-order.component';
import { PatientPaymentsInfoComponent } from './patient-payments-info/patient-payments-info.component';
import { AddIvfPaymentComponent } from 'projects/ivf/src/app/add-ivf-payment/add-ivf-payment.component';
import { OrderItemsComponent } from '../commonComponents/inventory/order-items/order-items.component';
import { InternalMessageComponent } from '../commonComponents/internal-message/internal-message.component';
import { ViewMessageComponent } from '../commonComponents/internal-message/view-message/view-message.component';
import { AddItemOrderComponent } from '../commonComponents/inventory/add-item-order/add-item-order.component';
// import { TreatmentPlanListComponent } from '../commonComponents/treatment-plan-list/treatment-plan-list.component';
// import { TreatmentPlanDefinitionComponent } from '../commonComponents/treatment-plan-definition/treatment-plan-definition.component';
// import { AddTreatmentDefinictionComponent } from '../commonComponents/add-treatment-definiction/add-treatment-definiction.component';
import { AddOrderPaymentComponent } from '../commonComponents/add-order-payment/add-order-payment.component';
import { DischargeInvoiceComponent } from './components/discharge-invoice/discharge-invoice.component';
import { AddInventoryComponent } from '../commonComponents/add-inventory/add-inventory.component';
import { AppTitleCaseDirective } from '../app-title-case.directive';
import { GlobalClickHandlerDirective } from '../global-click-handler.directive';
import { ProcessDateDirective } from '../process-date.directive';
import { EditRoomComponent } from './components/edit-room/edit-room.component';
import { DefaultValueDirective } from '../default-value.directive';
import { GenericReportTableComponent } from './generic-report-table/generic-report-table.component';
import { AppointmentNotesComponent } from './components/appointment-notes/appointment-notes.component';
import { AlertComponent } from './components/alert/alert.component';
import { EnquiryFormComponent } from '../enquiry-form/enquiry-form.component';
import { SurgeryNotesComponent } from './components/surgery-notes/surgery-notes.component';
import { AmbulanceHistoryComponent } from './components/ambulance-history/ambulance-history.component';
import { BedInfoComponent } from './components/bed-info/bed-info.component';
import { EditStaffComponent } from './components/edit-staff/edit-staff.component';
import { SkeltonLoaderComponent } from 'projects/ivf/src/app/skelton-loader/skelton-loader.component';
import { DialogComponentComponent } from 'projects/ivf/src/app/dialog-component/dialog-component.component';
import { PatientCreatAndBookAppointmentComponent } from './components/patient-creat-and-book-appointment/patient-creat-and-book-appointment.component';
import { DoctorPatientAppointmentBookingComponent } from './components/doctor-patient-appointment-booking/doctor-patient-appointment-booking.component';
import { CartDetailsComponent } from './components/cart-details/cart-details.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { NewRequestsComponent } from './components/new-requests/new-requests.component';
import { PrescriptionListComponent } from './components/prescription-list/prescription-list.component';
import { PharmaStepsComponent } from '../patient-pages/pharma-steps/pharma-steps.component';
import { PatientAddressDetailsComponent } from './components/patient-address-details/patient-address-details.component';
import { NewPatientAddressComponent } from './components/new-patient-address/new-patient-address.component';

// import { InventoryAdminComponent } from '../inventory-admin/inventory-admin.component';
// import { DisplayCategoriesComponent } from '../display-categories/display-categories.component';
// import { DisplaySuppliersComponent } from '../commonComponents/display-suppliers/display-suppliers.component';
// import { DisplayInventoryComponent } from '../commonComponents/display-inventory/display-inventory.component';
// import { DisplayAllOrdersComponent } from '../commonComponents/display-all-orders/display-all-orders.component';
// import { DisplayManufacturersComponent } from '../commonComponents/display-manufacturers/display-manufacturers.component';
// import { NewpatientComponent } from './models/newpatient/newpatient.component';
// import { NewpatientComponent } from './models/newpatient/newpatient.component';

const reusableComponents = [
  MainFooterComponent,MainHeaderComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    NgxMatDatetimePickerModule,NgxMatTimepickerModule,
    ReactiveFormsModule,

    FeedbackModule,
    NgxPaginationModule, MatDatepickerModule, MatInputModule, MatFormFieldModule,
    Ng2TelInputModule,
  ],

  declarations: [
    reusableComponents,
    UserVitalsComponent,
    UserVitalsHistoryComponent,
    ViewPrescriptionsModalComponent,
    DisplayPrescriptionModalComponent,
    PharmacyCreatePasswordComponent,
    FooterComponent,
    NavBarComponent,
    SidebarComponent,
    docNavBarComponent,
    docSidebarComponent,
    CameraComponent,
    DeviceSelectComponent,
    SettingsComponent,
    RoomsComponent,
    ParticipantsComponent,
    ResetPasswordComponent,
    LoaderComponent,
    ChangePasswordComponent,
    TimeFormat,
    doctorPatientComponent,
    DoctorCamerasComponent,
    DoctorSettingsComponent,
    DoctorParticipantsComponent,
    DoctorRoomsComponent,
    ViewRatingsComponent,
    ViewRemindersComponent,
    ViewDoctorRemaindersComponent,
    StarRatingComponent,
    InfoComponentComponent,
    AfterRegisterComponent,
    CancelPatientAppointmentComponent,
    DiagnosticSidebarComponent,
    AgePipe,
    DemoNumber,
    SplitPipe,
    SearchPipe,
    FormatTime,
    NumberToWordsPipe,
    LetterOnlyPipe,
    Split2Pipe,
    TruncatePipe,
    DiagnosticRedirectPagesComponent,
    PharmacyRedirectPagesComponent,
    CaretakerRedirectPagesComponent,
    LoginFooterComponent,
    AddressDetailsComponent,
    AddVitalsComponent,
    PrescriptionScreenComponent,
    ViewPrescriptionComponent,
    CommonForgotPasswordScreenComponent,
    CommonRegistrationScreenComponent,
    // NewpatientComponent,
    EnquiryFormComponent,
    PharmaStepsComponent,
    UnifiedValidationDirective,
    GlobalClickHandlerDirective,
    InputRestrictDirective,
    UnifiedValidationDirective,
    DefaultValueDirective,
    AutoResizeRowsDirective,
    AppTitleCaseDirective,
    ProcessDateDirective,
    ConvetToWordsPipe,
    ClinicOpdAppointmentBookingComponent,
    AppointmentBookingComponentNew,
    DoctorScheduleComponent,
    PaymentInformationScreenComponent,
    ConsentFormComponent,
    BookingConfirmScreenComponent,
    DiscountDefinitionComponent,
    DiscountRequestsComponent,
    PatientDetailsEditScreenComponent,
    PatientPrimaryDetailsScreenComponent,
    PatientPersonalDetailsScreenComponent,
    DcReportComponent,
    AppointmentDetailsComponent,
    CommonPrescriptionComponent,
    NewPharmaOrderComponent,
    PatAssignmentComponent,
    AssignRolesComponent,
    PharmaPaymentComponent,
    AddCategoryComponent,
    AddManufacturerComponent,
    AddSupplierComponent,
CreateOrdersComponent,
AddSubCategoryComponent,
AssignSupplierComponent,
OrderDeliveryComponent,
InventoryBatchsComponent,
    SaveSubcategoryComponent,
    SaveSupplierComponent,
    ViewSupplierInfoComponent,
    CurrentorPreviousInvOrdersComponent,
    OrderBatchInformationComponent,
    AddSubcatAtSupplieritemsComponent,
    CurrentBatchInfoComponent,
    AllSuppliersOfIndividualCategoryComponent,
    SingleSupplierInfoComponent,
    OrderDispatchComponent,
    SaveOrderDispatchComponent,
    SaveBulkOrderComponent,
    BulkOrderDeliveryComponent,
    PlaceOrderComponent,
    DiscountRequestInfoComponent,
    GenericReportsComponent,
    SendMessageComponent,
    TemplateFormComponent,
    PatientPaymentsInfoComponent,
    AddIvfPaymentComponent,
    OrderItemsComponent,
    InternalMessageComponent,
    ViewMessageComponent,
    AddItemOrderComponent,
    AddInventoryComponent,
    // TreatmentPlanListComponent,
    // TreatmentPlanDefinitionComponent,
    // AddTreatmentDefinictionComponent,
    AddOrderPaymentComponent,
    DischargeInvoiceComponent,
    EditRoomComponent,
    GenericReportTableComponent,
    AppointmentNotesComponent,
    AlertComponent,
    SurgeryNotesComponent,
    AmbulanceHistoryComponent,
    BedInfoComponent,
    EditStaffComponent,DialogComponentComponent,
    SkeltonLoaderComponent,
    PatientCreatAndBookAppointmentComponent,
    DoctorPatientAppointmentBookingComponent,
    CartDetailsComponent,
    OrderStatusComponent,
    NewRequestsComponent,
    PrescriptionListComponent,
    PatientAddressDetailsComponent,
    NewPatientAddressComponent
    // InventoryAdminComponent
    // DisplayCategoriesComponent,
    // DisplaySuppliersComponent,
    // DisplayInventoryComponent,
    // DisplayAllOrdersComponent,
    // DisplayManufacturersComponent,
  
  ],
  exports: [
    reusableComponents,
    UserVitalsComponent,
    UserVitalsHistoryComponent,
    ViewPrescriptionsModalComponent,
    DisplayPrescriptionModalComponent,
    LoginFooterComponent,
    SkeltonLoaderComponent,
    FooterComponent,
    NavBarComponent,
    SidebarComponent,
    doctorPatientComponent,
    docNavBarComponent,
    docSidebarComponent,
    CameraComponent,
    DeviceSelectComponent,
    AddVitalsComponent,
    SettingsComponent,
    ViewRatingsComponent,
    RoomsComponent,
    ParticipantsComponent,
    DoctorCamerasComponent,
    DoctorSettingsComponent,
    DoctorParticipantsComponent,
    DoctorRoomsComponent,
    LoaderComponent, ChangePasswordComponent, TimeFormat,
    StarRatingComponent, TruncatePipe,LetterOnlyPipe,
    
    DiagnosticSidebarComponent,CommonForgotPasswordScreenComponent,
    AgePipe, DemoNumber, SplitPipe, SearchPipe, Split2Pipe,FormatTime,
    NgxPaginationModule, MatDatepickerModule,MatInputModule,MatFormFieldModule,
    AddressDetailsComponent,PrescriptionScreenComponent,ViewPrescriptionComponent,NumberToWordsPipe,

    UnifiedValidationDirective,ConvetToWordsPipe,

    InputRestrictDirective,
    UnifiedValidationDirective,
    AutoResizeRowsDirective,
    ConvetToWordsPipe,
    AssignRolesComponent,
  ],
  entryComponents: [ChangePasswordComponent, CancelPatientAppointmentComponent, UserVitalsComponent,DoctorScheduleComponent,ConsentFormComponent,BookingConfirmScreenComponent
    , DisplayPrescriptionModalComponent,ClinicOpdAppointmentBookingComponent,AppointmentBookingComponentNew,PaymentInformationScreenComponent,DiscountDefinitionComponent,
    ViewPrescriptionsModalComponent,CommonPrescriptionComponent,UserVitalsHistoryComponent,DiscountRequestsComponent,PatientDetailsEditScreenComponent,DcReportComponent,AppointmentDetailsComponent,
    NewPharmaOrderComponent,PatAssignmentComponent,PharmaPaymentComponent,AddCategoryComponent,AddManufacturerComponent,
    AddSupplierComponent,
CreateOrdersComponent,
AddSubCategoryComponent,
AssignSupplierComponent,
OrderDeliveryComponent,
InventoryBatchsComponent,
    SaveSubcategoryComponent,
    SaveSupplierComponent,
    ViewSupplierInfoComponent,
    CurrentorPreviousInvOrdersComponent,
    OrderBatchInformationComponent,
    AddSubcatAtSupplieritemsComponent,
    CurrentBatchInfoComponent,
    AllSuppliersOfIndividualCategoryComponent,
    SingleSupplierInfoComponent,
    OrderDispatchComponent,
    SaveOrderDispatchComponent,
    SaveBulkOrderComponent,
    BulkOrderDeliveryComponent,
    PlaceOrderComponent,
    DiscountRequestInfoComponent,
    SupplierOrderInvoiceComponent,
    SendMessageComponent,
    TemplateFormComponent,
    InternalMessageComponent,
    ViewMessageComponent,
    OrderItemsComponent,
    AddInventoryComponent,
    // AddItemOrderComponent,
    // TreatmentPlanDefinitionComponent,
    // AddTreatmentDefinictionComponent,
    AddItemOrderComponent,
    AddOrderPaymentComponent,
    DischargeInvoiceComponent,
    EditRoomComponent,
    AppointmentNotesComponent,
    AlertComponent,
    SurgeryNotesComponent,
    AmbulanceHistoryComponent,
    BedInfoComponent,
    EditStaffComponent,
    DialogComponentComponent,
    PatientCreatAndBookAppointmentComponent,
    CartDetailsComponent,
    OrderStatusComponent,
    PatientAddressDetailsComponent,
    NewPatientAddressComponent
    // DisplayCategoriesComponent,
    // DisplaySuppliersComponent,
    // DisplayInventoryComponent,
    // DisplayAllOrdersComponent,
    // DisplayManufacturersComponent,
  
  ],
  providers: [AuthService, LoaderService, AppointmentServices, VitalsService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true }]

})
export class SharedModule { }
